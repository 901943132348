<template>
  <b-modal size="lg" v-model="show" :title="title" hide-footer @hide="onHide">
    <b-list-group>
      <b-list-group-item v-for="item in list" :key="item.id">
        <div class="flex justify-content-between align-items-center">
          <span>{{ item.batch_name }}</span>
          <span v-if="item.status == 'success'">
            <el-tooltip class="item" effect="dark" content="Execution Success" placement="top">
              <font-awesome-icon icon="check-circle" class="text-success float-right"/>
            </el-tooltip>
          </span>
          <span v-else-if="item.status == 'failed'">
            <el-tooltip class="item" effect="dark" content="Execution Failed" placement="top">
              <font-awesome-icon icon="exclamation-circle" class="text-danger float-right"/>
            </el-tooltip>
          </span>
          <span v-else-if="item.status == 'loading'">
            <font-awesome-icon spin icon="spinner"/>&nbsp; Processing
          </span>
          <span v-else>
            <el-tooltip class="item" effect="dark" content="Waiting Approval" placement="top">
              <font-awesome-icon icon="question-circle" class="text-info float-right"/>
            </el-tooltip>
          </span>
        </div>
      </b-list-group-item>
    </b-list-group>
    <b-form-group label="Reject Reason" v-if="action == 'reject'">
      <b-textarea
        rows="3"
        v-model="v$.form.reject_reason.$model"
        placeholder="Input reject reason"/>
      <b-form-invalid-feedback :state="!v$.form.reject_reason.$invalid" v-if="v$.form.reject_reason.$errors[0]">
        <span>{{ v$.form.reject_reason.$errors[0].$message }}</span>
      </b-form-invalid-feedback>
    </b-form-group>
    <div class="d-flex float-right mt-2">
      <el-button @click="onSubmit" :loading="loading.submit" class="mr-2 ml-2" size="small" type="primary">Submit</el-button>
      <el-button @click="onHide" size="small" class="mr-2">Cancel</el-button>
    </div>
  </b-modal>
</template>
<script>
import { cloneDeep } from 'lodash';
import async from 'async';
import useVuelidate from '@vuelidate/core';
import {
  requiredIf,
} from '@vuelidate/validators';
import {
  APPROVE_REJECT_DISBURSEMENT,
} from '@/store/modules/disbursement';

export default {
  name: 'ApproveRejectDisburse',
  props: {
    action: {
      type: String,
      default: 'approve',
    },
    isShown: {
      type: Boolean,
      default: false,
    },
    batchList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      form: {
        reject_reason: { required: requiredIf(this.action === 'reject') },
      },
    };
  },
  data() {
    return {
      show: false,
      list: [],
      loading: {
        submit: false,
      },
      form: {
        reject_reason: null,
      },
    };
  },
  computed: {
    title() {
      return this.action === 'approve' ? 'Approve Disburse' : 'Reject Disburse';
    },
  },
  methods: {
    onHide() {
      this.$emit('onHide');
    },
    onSubmit() {
      let index = 0;
      this.loading.submit = true;
      async.eachLimit(this.list, 1, (item, cb) => {
        this.list[index].status = 'loading';
        this.$store.dispatch(APPROVE_REJECT_DISBURSEMENT, {
          id: item.id,
          action: this.action,
          data: this.form,
        }).then(() => {
          this.list[index].status = 'success';
          index += 1;
          cb();
        }).catch(() => {
          this.list[index].status = 'failed';
          index += 1;
          cb();
        });
      }, () => {
        this.loading.submit = false;
        this.$message({
          title: 'Success',
          type: 'success',
          message: `Sucessfully ${this.action} disbursement request`,
        });
        this.$parent.refreshList();
        this.show = false;
      });
    },
  },
  watch: {
    isShown() {
      this.show = this.isShown;
      this.list = cloneDeep(this.batchList).map((item) => {
        item.loading = false;
        item.status = '';
        return item;
      });
    },
  },
};
</script>
