<template>
  <div class="container-xl" v-if="needTfa">
    <div class="row row-deck row-cards mb-3">
      <div class="card items-center">
        <div class="card-header w-100" style="display: block;">
          <div class="card-title text-center">
            Activate Two-Factor Authenticate
          </div>
        </div>
        <div class="card-body col-6">
          <ol style="list-style: auto;">
            <li>Before continue access this page, you need to activate two-factor authenticate. Please download <b>Google Authenticator</b> on <a :href="playstore" target="_blank">Google Playstore</a> or <a :href="appstore" target="_blank">App Store</a></li>
            <li class="mt-2">Scan this QR with Google Authenticator.<br/>
              <img :src="qrcode"/>
            </li>
            <li>Enter the authentication code for activate Two-Factor Authentication.
              <el-input @keydown.native="handlerInput" show-word-limit maxlength="6" class="mt-2" v-model="form.code" type="text" placeholder="Input 6 length authentication code"/>
              <el-button @click="onSubmit" :loading="loading.submit" size="small" class="mt-2" type="primary">Submit</el-button>
            </li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  INIT_2FA, ACTIVATE_2FA,
} from '@/store/modules/auth';
import popupErrorMessages from '@/library/popup-error-messages';

export default {
  name: 'Activate2FA',
  props: {
    needTfa: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      playstore: 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US',
      appstore: 'https://apps.apple.com/us/app/google-authenticator/id388497605',
      form: {
        code: '',
      },
      qrcode: '',
      loader: null,
      loaderStack: 0,
      loading: {
        submit: false,
      },
    };
  },
  methods: {
    showLoader() {
      if (this.loaderStack === 0) {
        this.loader = this.$loading.show();
      }
      this.loaderStack += 1;
    },
    hideLoader() {
      this.loaderStack -= 1;
      if (this.loaderStack === 0) {
        this.loader.hide();
      }
    },
    async generateQr() {
      this.showLoader();
      const response = await this.$store.dispatch(INIT_2FA);
      this.qrcode = response.qr;
      this.hideLoader();
    },
    async onSubmit() {
      this.showLoader();
      this.loading.submit = true;
      this.$store.dispatch(ACTIVATE_2FA, this.form).then(() => {
        this.$message({
          title: 'Success',
          type: 'success',
          message: 'Successfully activate two-factor authentication',
        });
        this.$parent.loadProfile();
      }).catch((err) => {
        popupErrorMessages(err?.response?.data).catch(() => {});
      });
      this.loading.submit = false;
      this.hideLoader();
    },
    handlerInput(evt) {
      const theEvent = evt || window.event;
      let key = '';
      if ([8, 91].includes(theEvent.keyCode) || theEvent.ctrlKey || theEvent.metaKey) {
        return;
      }
      // Handle paste
      if (theEvent.type === 'paste') {
        key = theEvent.clipboardData.getData('text/plain');
      } else {
      // Handle key press
        key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode(key);
      }
      const regex = /[0-9]/;
      // console.log(key);
      if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
      }
    },
  },
  watch: {
    needTfa() {
      console.log('generate qr...');
      if (this.needTfa) {
        this.generateQr();
      }
    },
  },
};
</script>