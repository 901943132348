<template>
  <div>
    <activate-tfa :needTfa="need_tfa"/>
    <div class="container-xl" v-if="!need_tfa">
      <div class="card col-lg-12">
        <div class="card-header justify-content-between">
          <div class="card-title">
            Disbursement List
          </div>
          <el-button @click="handlerBtnCreateDisburse" type="primary" size="mini"><font-awesome-icon icon="hand-holding-usd"/> Create new disbursement</el-button>
        </div>
        <div class="table-responsive">
          <el-table :data="list"
            ref="list_table"
            @selection-change="handlerSelectionChange"
            class="table card-table text-nowrap table-vcenter"
            style="width: 100%">
            <el-table-column
              type="selection"
              :selectable="(row) => row.status === 'waiting_approval'"
              width="65"/>
            <el-table-column
              prop="batch_ref"
              label="Ref"
              width="220"/>
            <el-table-column
              prop="batch_name"
              label="Batch Name"
              width="220">
              <template slot-scope="scope">
                {{ truncate(scope.row.batch_name, { length: 30 }) }}
              </template>
            </el-table-column>
            <el-table-column
              prop="company_name"
              label="Company Name"
              width="300">
              <template slot-scope="scope">
                {{ truncate(scope.row.company_name, { length: 50 }) }}
              </template>
            </el-table-column>
            <el-table-column
              prop="amount_str"
              label="Amount"
              width="120"/>
            <el-table-column
              prop="created_at_str"
              label="Request Date"
              width="150"/>
            <el-table-column
              prop="status"
              label="Status"
              width="150">
              <template slot-scope="scope">
                <el-popover
                  v-if="scope.row.status === 'reject'"
                  placement="left"
                  title="Reject Reason"
                  width="200"
                  trigger="hover"
                  :content="scope.row.reject_reason">
                  <span slot="reference" class="tag" :class="scope.row.status_color">{{ scope.row.status_str }}</span>
                </el-popover>
                <span v-else class="tag" :class="scope.row.status_color">{{ scope.row.status_str }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="#"
              width="120">
              <template slot-scope="scope">
                <button :disabled="!currentRole.can_edit && !currentRole.can_param1" class="btn btn-secondary btn-sm dropdown-toggle" data-toggle="dropdown">Actions</button>
                <div v-if="currentRole.can_edit || currentRole.can_param1" class="dropdown-menu dropdown-menu-right dropdown-menu-arrow float-right p-2" style="width: 200px">
                  <a v-if="currentRole.can_param1" @click="handlerClickAction(scope.row, 'view_recipient')" title="View disburse recipient" class="dropdown-item btn-sm cursor-pointer"><font-awesome-icon class="text-brand" icon="eye"/>&nbsp; View Recipient</a>
                  <a @click="handlerClickAction(scope.row, 'download')" title="Download disburse detail" class="dropdown-item btn-sm cursor-pointer"><font-awesome-icon class="text-brand" icon="download"/>&nbsp; Download Report</a>
                </div>
              </template>
            </el-table-column>
            <template slot="empty">
              <el-empty description="No data found" :image="emptyStateImage"></el-empty>
            </template>
          </el-table>
        </div>
        <div class="card-footer d-flex justify-content-between">
          <div>
            <el-button @click="handlerBtnApproval('approve')" v-if="currentRole.can_param1 && !isEmptyChecked" size="mini" type="primary">Approve</el-button>
            <el-button @click="handlerBtnApproval('reject')" v-if="currentRole.can_param1 && !isEmptyChecked" size="mini" type="danger">Reject</el-button>
          </div>
          <div>
            <small>Showing {{ showingFrom }} to {{ showingUntil }} of {{ total_rows }} entries</small>
            <div class="inline-table">
              <b-pagination
                class="table-cell"
                @change="pageChangeHandler"
                :per-page="per_page"
                :limit="5"
                :total-rows="total_rows"
                v-model="page"
              />
            </div>
          </div>
        </div>
        <create-disbursement @onHide="showModalCreate = false; getListDisburse()" :is-shown="showModalCreate"/>
        <approve-reject-disburse :action="approvalType" :batch-list="checked_batch" @onHide="showModalApproveReject = false" :is-shown="showModalApproveReject" />
      </div>
    </div>
  </div>
</template>
<script>
import { truncate, isEmpty, uniqBy } from 'lodash';
import {
  MY_PROFILE,
} from '@/store/modules/auth';
import ActivateTfa from '../components/Activate2FA.vue';
import {
  GET_DISBURSEMENTS, DOWNLOAD_DETAIL_DISBURSEMENT,
} from '@/store/modules/disbursement';
import emptyStateImage from '@/assets/images/empty-state.png';
import CreateDisbursement from './disbursement/CreateDisbursement.vue';
import ApproveRejectDisburse from './disbursement/ApproveRejectDisburse.vue';

export default {
  components: {
    ActivateTfa,
    CreateDisbursement,
    ApproveRejectDisburse,
  },
  name: 'Disbursement',
  metaInfo: {
    title: 'Disbursement',
  },
  computed: {
    myProfile() {
      return this.$store.getters.currentMyProfile;
    },
    showingFrom() {
      return this.total_rows ? ((this.page - 1) * this.per_page) + 1 : 0;
    },
    showingUntil() {
      if (this.total_rows) {
        if (this.list.length < this.per_page) {
          return this.total_rows;
        }
        return this.page * this.per_page;
      }
      return 0;
    },
    currentRole() {
      return this.$store.getters.current_role;
    },
    isEmptyChecked() {
      return isEmpty(Object.keys(this.temp_checked).filter((key) => !isEmpty(this.temp_checked[key])));
    },
  },
  data() {
    return {
      truncate,
      showModalRecipient: false,
      showModalCreate: false,
      showModalApproveReject: false,
      showModalHistory: false,
      emptyStateImage,
      need_tfa: false,
      loader: null,
      list: [],
      total_rows: 0,
      per_page: 10,
      page: 1,
      selected_batch: {},
      loading: {
        detail: false,
      },
      checked_batch: [],
      temp_checked: {},
      checked: {},
      approvalType: 'approve',
    };
  },
  async mounted() {
    this.loader = this.$loading.show();
    await this.loadProfile();
    this.loader.hide();
  },
  methods: {
    async loadProfile() {
      await this.$store.dispatch(MY_PROFILE);
      if (!this.myProfile.tfa_active) this.need_tfa = true;
      if (this.myProfile.tfa_active) {
        this.need_tfa = false;
        await this.getListDisburse();
      }
    },
    async refreshList() {
      this.page = 1;
      await this.getListDisburse();
      this.temp_checked = {};
    },
    async getListDisburse() {
      await this.$store.dispatch(GET_DISBURSEMENTS, {
        page: this.page,
        per_page: this.per_page,
      });
      const response = this.$store.getters.disbursements;
      this.list = response.rows;
      this.total_rows = response.count;
    },
    async pageChangeHandler(page) {
      this.checked[`page${this.page}`] = this.temp_checked[`page${this.page}`] || [];

      this.page = page;
      this.loader = this.$loading.show();
      await this.getListDisburse();
      if (this.checked[`page${this.page}`]) {
        this.checked[`page${this.page}`].forEach((v) => {
          const obj = this.list.find((k) => k.id === v.id && v.status === 'waiting_approval');
          if (obj) this.$refs.list_table.toggleRowSelection(obj);
        });
      }
      this.loader.hide();
    },
    handlerClickAction(item, action) {
      this.selected_batch = item;
      if (action === 'view_recipient') {
        // this.showModalRecipient = true;
        // this.loading.detail = true;
        this.$router.push({
          name: 'Disbursement Detail',
          params: {
            id: item.id,
          },
        });
      } else if (action === 'view_histories') {
        this.showModalHistory = true;
      } else if (action === 'download') {
        this.downloadReport(item);
      }
    },
    handlerSelectionChange(value) {
      this.$set(this.temp_checked, `page${this.page}`, value);
    },
    handlerBtnCreateDisburse() {
      this.showModalCreate = true;
    },
    handlerBtnApproval(action) {
      this.checked_batch = [];
      Object.keys(this.temp_checked).forEach((key) => {
        this.checked_batch.push(...this.temp_checked[key]);
      });
      this.checked_batch = uniqBy(this.checked_batch, 'id');
      this.approvalType = action;
      this.showModalApproveReject = true;
    },
    async downloadReport(item) {
      this.loader = this.$loading.show();
      await this.$store.dispatch(DOWNLOAD_DETAIL_DISBURSEMENT, {
        id: item.id,
      }).then((data) => {
        // console.log(data);
        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(data)}`;
        anchor.target = '_blank';
        anchor.download = `${item.batch_name}.csv`;
        anchor.click();
      });
      this.loader.hide();
    },
  },
};
</script>
