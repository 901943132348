<template>
<b-modal v-model="show" title="Create Disbursement" hide-footer @hide="onHide">
  <b-form @submit.prevent="onSubmit">
    <b-form-group label="Batch Name">
      <b-input
        v-model="v$.form.batch_name.$model"
        placeholder="Input batch name"
        type="text"/>
      <b-form-invalid-feedback :state="!v$.form.batch_name.$invalid" v-if="v$.form.batch_name.$errors[0]">
        <span>{{ v$.form.batch_name.$errors[0].$message }}</span>
      </b-form-invalid-feedback>
    </b-form-group>
    <!-- type -->
    <b-form-group label="Disbursement Destination Type">
      <el-select class="mb-2 w-100" v-model="v$.form.disburse_destination_type.$model" placeholder="Select destination for disburse">
        <el-option label="Coin" value="coin"></el-option>
        <el-option label="Wallet" value="wallet"></el-option>
      </el-select>
    </b-form-group>

    <b-form-group label="Company" v-if="v$.form.disburse_destination_type.$model === 'wallet'">
      <el-select class="mb-2" :loading="loading.company" v-model="v$.form.company_id.$model" @change="handleCompanyChange" placeholder="Select or search company" style="width: 100%" filterable remote :remote-method="searchCompany">
        <el-option
          v-for="item in companies.rows"
          :key="item.company_id"
          :label="item.company_name"
          :value="item.company_id">
        </el-option>
      </el-select>
      <!-- :disabled="!item.merchant_mbayar" -->
      <!-- <span v-if="form.company_id && !loading.balance">Balance: <strong>Rp.{{ company_balance.toLocaleString() }}</strong></span>
      <span v-if="form.company_id && loading.balance">Balance: <strong>Loading...</strong></span>
      <b-form-invalid-feedback :state="!v$.form.company_id.$invalid" v-if="v$.form.company_id.$errors[0]">
        <span>{{ v$.form.company_id.$errors[0].$message }}</span>
      </b-form-invalid-feedback> -->
    </b-form-group>
    <b-form-group label="Company" v-if="v$.form.disburse_destination_type.$model === 'coin'">
      <el-select class="mb-2" :loading="loading.company" v-model="v$.form.company_id.$model" @change="handleCompanyChange" placeholder="Select or search company" style="width: 100%" filterable remote :remote-method="searchCompany">
        <el-option
          v-for="item in companies.rows"
          :key="item.company_id"
          :label="item.company_name"
          :value="item.company_id">
        </el-option>
      </el-select>
      <b-form-invalid-feedback :state="!v$.form.company_id.$invalid" v-if="v$.form.company_id.$errors[0]">
        <span>{{ v$.form.company_id.$errors[0].$message }}</span>
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group label="Recipient from">
      <el-select @change="recipientFromHandler" class="mb-2 w-100" v-model="recipient_from" placeholder="Select recipient input source">
        <el-option label="File" value="file"></el-option>
        <el-option label="Choose from Existing User" value="select"></el-option>
        <el-option label="Select All from Existing User" value="all"></el-option>
      </el-select>
    </b-form-group>
    <b-form-group label="Amount"  v-if="recipient_from == 'all'">
      <el-input @keydown.native="handlerInputSelectAll"  v-model="form_select.amount" type="number" placeholder="Input amount"/>
    </b-form-group>
    <b-form-group label="Total Amount"  v-if="recipient_from == 'all'">
      <el-input v-model="totalAmount" :disabled="true"/>
    </b-form-group>
    <b-form-group label="Total Users"  v-if="recipient_from == 'all'">
      <el-input v-model="total_rows" :disabled="true"/>
    </b-form-group>
    <b-form-group label="Recipient File" v-if="recipient_from == 'file'">
      <b-form-file
        v-model="v$.form.file.$model"
        @input="handlerInputFile"
        :state="Boolean(v$.form.file.$model)"
        class="mt-2 border-1 p-2 text-sm"
        accept=".csv"
        placeholder="Choose a file or drop it here..."
        drop-placeholder=" "
      ></b-form-file>
      <el-button size="mini" class="mt-2" type="info" @click="handlerBtnValidate" :disabled="!Boolean(v$.form.file.$model)">Validate Recipient</el-button>
      <b-form-invalid-feedback :state="!v$.form.file.$invalid" v-if="v$.form.file.$errors[0]">
        <div class="w-full flex flex-row justify-content-between">
          <span>*only .csv file allowed</span>
          <!-- <a href="../../../assets/samples/sample-disbursement.csv" download>Download Example CSV</a> -->
        </div>
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group label="Choose Recipient" v-if="recipient_from == 'select'">
      <el-button @click="handlerChooseRecipient" size="small">Select recipient</el-button>
      <span v-if="form.details.length > 0" style="line-height: 25px"><br/>
        Total recipient: <b>{{ form.details.length }}</b><br/>
        Total amount: <b>Rp.{{ sumBy(form.details, 'amount').toLocaleString() }}</b>
      </span>
      <b-form-invalid-feedback :state="!v$.form.details.$invalid" v-if="v$.form.details.$errors[0]">
        <span>{{ v$.form.details.$errors[0].$message }}</span>
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group label="OTP" v-if="v$.form.disburse_destination_type.$model === 'wallet'">
      <el-input @keydown.native="handlerInput" show-word-limit maxlength="6" v-model="v$.form.otp.$model" type="text" placeholder="Input 6 length authentication code"/>
      <b-form-invalid-feedback :state="!v$.form.otp.$invalid" v-if="v$.form.otp.$errors[0]">
        <span>{{ v$.form.otp.$errors[0].$message }}</span>
      </b-form-invalid-feedback>
    </b-form-group>
  </b-form>
  <div class="d-flex float-right" v-if="form.disburse_destination_type === 'coin'">
    <el-button @click="onSubmit" :loading="loading.submit" class="mr-2 ml-2" size="small" type="primary">Submit</el-button>
    <el-button @click="onHide" size="small" class="mr-2">Cancel</el-button>
  </div>
  <div class="d-flex float-right" v-if="form.disburse_destination_type === 'wallet' && recipient_from == 'select'">
    <el-button :disabled="!validatedRecipient || isOtpEmpty" @click="onSubmit" :loading="loading.submit" class="mr-2 ml-2" size="small" type="primary">Submit</el-button>
    <el-button @click="onHide" size="small" class="mr-2">Cancel</el-button>
  </div>
  <div class="d-flex float-right" v-if="form.disburse_destination_type === 'wallet' && recipient_from == 'all'">
    <el-button :disabled="isOtpEmpty" @click="onSubmit" :loading="loading.submit" class="mr-2 ml-2" size="small" type="primary">Submit</el-button>
    <el-button @click="onHide" size="small" class="mr-2">Cancel</el-button>
  </div>
  <div class="d-flex float-right" v-if="form.disburse_destination_type === 'null'">
    <el-button :disabled="true" @click="onSubmit" :loading="loading.submit" class="mr-2 ml-2" size="small" type="primary">Submit</el-button>
    <el-button @click="onHide" size="small" class="mr-2">Cancel</el-button>
  </div>
  <div class="d-flex float-right" v-if="form.disburse_destination_type === 'wallet' && recipient_from == 'file'">
    <el-button :disabled="isOtpEmpty" @click="onSubmit" :loading="loading.submit" class="mr-2 ml-2" size="small" type="primary">Submit</el-button>
    <el-button @click="onHide" size="small" class="mr-2">Cancel</el-button>
  </div>
  <recipient-table
    @onSubmit="onSubmitRecipientTable"
    @onHide="showModalRecipientTable = false"
    :company-id="form.company_id.toString()"
    :is-show="showModalRecipientTable" />
  <preview-recipient-list
    @onHide="showModalPreviewRecipient = false"
    @onSubmit="onSubmitRecipientCsv"
    :recipients="csv_data"
    :company-id="form.company_id.toString()"
    :is-show="showModalPreviewRecipient" />
</b-modal>
</template>
<script>
import { sumBy, uniqBy } from 'lodash';
import useVuelidate from '@vuelidate/core';
import {
required, requiredIf,
} from '@vuelidate/validators';
import { GET_COMPANIES, CHECK_MBAYAR_BALANCE } from '@/store/modules/companies';
import { CREATE_DISBURSEMENT } from '@/store/modules/disbursement';
import popupErrorMessages from '@/library/popup-error-messages';
import RecipientTable from './RecipientTable.vue';
import PreviewRecipientList from './PreviewRecipientList.vue';
import { GET_APPUSERS } from '@/store/modules/app_user';

export default {
components: { RecipientTable, PreviewRecipientList },
name: 'CreateDisbursement',
props: {
  isShown: {
    type: Boolean,
    default: false,
  },
},
setup() {
  return {
    v$: useVuelidate(),
  };
},
validations() {
  return {
    form: {
      company_id: { required: requiredIf(this.disburse_destination_type === 'wallet') },
      otp: { required: requiredIf(this.disburse_destination_type === 'wallet') },
      batch_name: { required },
      disburse_destination_type: { required },
      file: { required: requiredIf(this.recipient_from === 'file') },
      details: { required: requiredIf(this.recipient_from === 'select') },
    },
  };
},
data() {
  return {
    total_rows: 0,
    showModalPreviewRecipient: false,
    sumBy,
    loading: {
      submit: false,
      company: false,
      balance: false,
    },
    show: false,
    form: {
      company_id: '',
      otp: '',
      batch_name: '',
      disburse_destination_type: 'coin',
      file: null,
      details: [],
    },
    companies: {
      rows: [],
      count: 0,
    },
    timeoutSearchCompany: null,
    company_balance: 0,
    recipient_from: 'file',
    disburse_destination_type: 'null',
    showModalRecipientTable: false,
    csv_data: [],
    csv_length: 0,
    validatedRecipient: false,
    form_select: {
      amount: ''
    }
  };
},
methods: {
  onHide() {
    this.resetForm();
    this.$emit('onHide');
  },
  async loadCompanies({ search_keyword = '' } = {}) {
    this.loading.company = true;
    await this.$store.dispatch(GET_COMPANIES, {
      search_keyword,
    });
    this.loading.company = false;
    this.companies = this.$store.getters.companies;
  },
  async getListApp() {
    this.loading.table = true;
    await this.$store.dispatch(GET_APPUSERS, {
      page: 1,
      per_page: 10,
      company_id: this.form.company_id,
      order_by: "full_name:asc"
    }).catch(() => {});
    const { rows, count } = this.$store.getters.app_users;
    this.total_rows = count || 0;
    this.list = rows;
    this.loading.table = false;
  },
  async handleCompanyChange() {
    await this.getListApp();
  },
  searchCompany(value) {
    clearTimeout(this.timeoutSearchCompany);
    this.timeoutSearchCompany = setTimeout(() => {
      this.loadCompanies({
        search_keyword: value,
      });
    }, 1000);
  },
  handlerInput(evt) {
    const theEvent = evt || window.event;
    let key = '';
    if ([8, 91].includes(theEvent.keyCode) || theEvent.ctrlKey || theEvent.metaKey) {
      return;
    }
    // Handle paste
    if (theEvent.type === 'paste') {
      key = theEvent.clipboardData.getData('text/plain');
    } else {
    // Handle key press
      key = theEvent.keyCode || theEvent.which;
      key = String.fromCharCode(key);
    }
    const regex = /[0-9]/;
    // console.log(key);
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  },
  resetForm() {
    this.form = {
      company_id: '',
      otp: '',
      batch_name: '',
      disburse_destination_type: '',
      file: null,
      details: [],
    };
  },
  async onSubmit() {
    this.v$.form.$touch();
    if (this.v$.form.$error) return;
    this.loading.submit = true;
    let data = { ...this.form };
    if (this.recipient_from === 'all') {
      data.details = [
        {
          owned_by: null,
          phone_number: "all",
          amount: this.form_select.amount,
          name: "all"
        }
      ];
    } else {
      delete data.file;
    }
    try {
      await this.$store.dispatch(CREATE_DISBURSEMENT, data);
      this.$message({
        title: 'Success',
        type: 'success',
        message: 'Successfully created disbursement request',
      });
      this.resetForm();
      this.show = false;
      this.$parent.getListDisburse();
    } catch (err) {
      console.log(err);
      if (err.response && err.response.data) {
        const resp = err.response.data;
        if (resp.error.code === 1019) {
          this.form.otp = '';
          this.v$.form.$ ();
        }
        popupErrorMessages(err.response.data);
      }
    } finally {
      this.loading.submit = false;
    }
  },
  handlerInputSelectAll(event) {
    console.log('Amount input: ', this.form_select.amount);
  },
  async checkBalance() {
    this.loading.balance = true;
    await this.$store.dispatch(CHECK_MBAYAR_BALANCE, {
      id: this.form.company_id,
    }).then(({ data }) => {
      this.company_balance = data.balance.balance;
    }).catch(() => {});
    this.loading.balance = false;
  },
  handlerChangeCompany() {
    this.checkBalance();
  },
  handlerChooseRecipient() {
    this.showModalRecipientTable = true;
  },
  onSubmitRecipientTable(data) {
    this.form.details = data.map((v) => {
      const {
        phone_number, amount, full_name, owned_by,
      } = v;
      return {
        owned_by,
        phone_number,
        amount,
        name: full_name,
      };
    });
  },
  onSubmitRecipientCsv(data) {
    this.validatedRecipient = true;
    this.form.details = data.map((v) => {
      const {
        phone_number, amount, name, account_name: wallet_name, status: wallet_status,
      } = v;
      return {
        phone_number,
        /* eslint-disable radix */
        amount: parseInt(amount),
        name,
        wallet_name,
        wallet_status,
      };
    });
  },
  handlerBtnValidate() {
    // if (!this.form.company_id) {
    //   this.$message({
    //     title: 'Oops',
    //     type: 'warning',
    //     message: 'Please select company before validate recipient.',
    //   });
    //   return;
    // }
    this.showModalPreviewRecipient = true;
  },
  async handlerInputFile() {
    // const l = this.$loading.show();
    try {
      if (!this.form.file && !this.form.company_id) {
        return;
      }
      this.validatedRecipient = false;
      const str = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsText(this.form.file);
        reader.onload = () => resolve(reader.result.replace(/\\r\\n|\\r|\\n|\\n\\r/igm, ''));
        reader.onerror = (error) => reject(error);
      });
      const csv_split = str.split('\n');
      /* eslint-disable no-param-reassign */
      const header = csv_split[0].split(/;|,/ig).map((v) => {
        v = v.replace(/\\r\\n|\\r|\\n|\\n\\r/ig, '').replace('\r', '');
        return v;
      });
      const obj = [];
      this.csv_length = csv_split.length;
      for (let i = 1; i <= csv_split.length; i += 1) {
        const obj_to_push = {};
        if (csv_split[i]) {
          const str_split = csv_split[i].split(/;|,/ig);
          header.forEach((k, ki) => {
            obj_to_push[k] = str_split[ki];
          });
          obj.push(obj_to_push);
        }
      }
      this.csv_data = uniqBy(obj, 'phone_number');
    } catch (error) {
      console.log(error);
      this.$message({
        title: 'Oops',
        type: 'warning',
        message: 'Failed read csv file.',
      });
    }
    // l.hide();
  },
  recipientFromHandler(value) {
    this.validatedRecipient = value === 'select';
  },
},
async mounted() {
  await this.loadCompanies();
  await this.getListApp();
},
computed: {
  isOtpEmpty() {
    return !this.v$.form.otp.$model || this.v$.form.otp.$model.trim() === '';
  },
  totalAmount() {
    const amount = parseFloat(this.form_select.amount) || 0;
    return (this.total_rows * amount).toLocaleString();
  }
},
watch: {
  isShown() {
    this.show = this.isShown;
    this.v$.form.$touch();
    if (this.show) {
      this.loadCompanies();
    }
  },
},
};
</script>
<style>
.custom-file-label {
font-size: .75rem;
}
</style>